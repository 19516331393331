<template>
  <div>
    <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Dashboard  </h4>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header">
				<!-- <div class="text-right mb-1 cursor-pointer" @click="filterClick()"><i class="mdi mdi-filter"></i> </div> -->
			</b-col>
		</b-row>

 <!--   <lottie-vue-player 
    autoplay
  controls
  loop
  mode="normal"
  :src="load_lottie()"
  style="width: 320px"
   >
        </lottie-vue-player>

        <lottie :options="defaultOptions" :height="400" :width="400" v-on:animCreated="handleAnimation"/>
        <div>
            <p>Speed: x{{animationSpeed}}</p>
            <input type="range" value="1" min="0" max="3" step="0.5"
                   v-on:change="onSpeedChange" v-model="animationSpeed">
        </div>
        <button v-on:click="stop">stop</button>
        <button v-on:click="pause">pause</button>
        <button v-on:click="play">play</button> -->
    <b-row>
      <div class="col-xl-4 mb-4 col-lg-4 col-12">
				<div class="card card-design" >
					<div class=" Drow">
						<div class="col-12">
							<div class="card-body text-nowrap">
                <h5 class="card-title mt-4">Hi! {{user_name}}</h5>
								<div class="flex justify-content-between mt-3">
									<div class="text-left ">
										
										
										<h5 class="text-light mt-4 ">Total Booking Value<br>
                      <span v-html="currency_check(numberWithCommas(booking_count.booking_value))"></span>
                    </h5>
									</div>
									<div class="text-right ">
										<h5 class="text-light mt-4 ">Total Admin Value<br>
                      <span v-html="currency_check(numberWithCommas(booking_count.admin_value))"></span>
                    </h5>
									</div>
								</div>
							</div>
						</div>
						<div class="col-5 text-center text-sm-left">
							<div class="card-body pb-0 px-0 px-md-4">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-8 mb-4 col-lg-8 col-12">
				<div class="card h-100">
					<div class="card-header">
						<div class="d-flex justify-content-between mb-3">
							<h5 class="card-title mb-0">Statistics</h5>
							<small class="text-muted">Updated </small>
						</div>
					</div>
					<div class="card-body pt-0 mb-2">
						<b-row>

							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/my-booking')">
									<div class="mr-2">
										<span class="display-4 text-success fs-5">
											<i class="mdi mdi-book"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Booked</span>
										<h5 class="font-medium mb-0"> {{ booking_count.total_confimed_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_confimed_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" :style="{width: sumofPercentage(booking_count.total_confimed_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_confimed_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

							</b-col>
							
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/my-booking')">
									<div class="mr-2">
										<span class="display-4 text-danger">
											<i class="mdi mdi-block-helper"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Blocked</span>
										<h5 class="font-medium mb-0">{{  booking_count.total_blocked_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_blocked_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-danger" role="progressbar" :style="{width: sumofPercentage(booking_count.total_blocked_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_blocked_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/drafts-booking')">
									<div class="mr-2">
										<span class="display-4 text-primary">
											<i class="mdi mdi-content-save"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Drafted</span>
										<h5 class="font-medium mb-0">{{ booking_count.total_draft_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_draft_booking,this.allcounts)}}%</span></h5>
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-info" role="progressbar" :style="{width: sumofPercentage(booking_count.total_draft_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_draft_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
							<b-col cols="12" lg="3" md="6">
								<div class="d-flex align-items-center cursor-pointer" @click="$router.push('/drafts-booking')">
									<div class="mr-2">
										<span class="display-4 text-warning">
											<i class="mdi mdi-file-outline"></i>
										</span>
									</div>
									<div>
										<span id="money_l" class="text-dark">Quotation</span> 
										<h5 class="font-medium mb-0">{{ booking_count.total_quot_booking }} <span class="text-success fs-10">{{sumofPercentage(booking_count.total_quot_booking,this.allcounts)}}%</span></h5>
										
									</div>
								</div>
								<div class="progress w-75" style="height:4px">
                <div class="progress-bar bg-warning" role="progressbar" :style="{width: sumofPercentage(booking_count.total_quot_booking,this.allcounts)+'%'}" :aria-valuenow="sumofPercentage(booking_count.total_quot_booking,this.allcounts)" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
							</b-col>
						</b-row>
						
					</div>
				</div>
			</div>
     <!--  <lottie :options="defaultOptions" :height="400" :width="400" v-on:animCreated="handleAnimation"/>
        <div>
            <p>Speed: x{{animationSpeed}}</p>
            <input type="range" value="1" min="0" max="3" step="0.5"
                   v-on:change="onSpeedChange" v-model="animationSpeed">
        </div>
        <button v-on:click="stop">stop</button>
        <button v-on:click="pause">pause</button>
        <button v-on:click="play">play</button> -->

      

      <b-col lg="12" cols="12" md="12" class="d-flex align-items-stretch">
        <b-row>
          <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Franchises</span>
                  <h4>0</h4>
                </div>
                <div class="ml-auto">
                 <div>
                  <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="150px"
                      :options="walletChart.chartOptions"
                      :series="walletChart.series"
                    ></vue-apex-charts>
                 </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Customer</span>
                  <h4> {{ booking_count.customers || 0 }}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <vue-apex-charts
                      type="area"
                      height="70px"
                      width="150px"
                      :options="walletChart.chartOptions"
                      :series="walletChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
            <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Service Provider</span>
                  <h4>0</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <vue-apex-charts
                      type="area"
                      height="70px"
                      width="150px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Vendors</span>
                  <h4>{{ booking_count.vendors }}</h4>
                </div>
                <div class="ml-auto">
                  <div>
                    <vue-apex-charts
                      type="bar"
                      height="70px"
                      width="150px"
                      :options="referralChart.chartOptions"
                      :series="referralChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          
           <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Venues</span>
                  <h4> {{ booking_count.venues }}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <vue-apex-charts
                      type="area"
                      height="70px"
                      width="150px"
                      :options="walletChart.chartOptions"
                      :series="walletChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
           <b-col lg="4" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Child Venues</span>
                  <h4> {{ booking_count.child_venues }}</h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <vue-apex-charts
                      type="area"
                      height="70px"
                      width="150px"
                      :options="walletChart.chartOptions"
                      :series="walletChart.series"
                    ></vue-apex-charts>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
     
    </b-row>
  
   
 <b-row>
  <b-col md="6">
    <b-card class="mb-4 w-100">
      <h3 class="font-weight-light">Upcoming Bookings</h3>
      <b-skeleton-table v-if="load_bookings"
  :rows="5"
  :columns="5"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
      <b-table v-else
        :items="get_upcoming_booking"
        :fields="fields"
        responsive
        hover
        bordered
        show-empty
        empty-text="No Data Available"
        style="max-width: 100%"
      >
      <template  #cell(price)="data">
						<span v-html="currency_check(numberWithCommas(data.item.price))"></span>
					</template>

      </b-table>
    </b-card>
  </b-col> 
  <b-col md="6">
    <b-card class="mb-4 w-100">
      <h3 class="font-weight-light">Last Registered Vendors</h3>
      <b-skeleton-table v-if="load_vendors"
  :rows="5"
  :columns="4"
  :table-props="{ bordered: true, striped: true }"
></b-skeleton-table>
      <b-table v-else
        :items="get_last_vendors"
        :fields="fields1"
        responsive
        hover
        bordered
        show-empty
        empty-text="No Data Available"
        style="max-width: 100%"
      >
      <template  #cell(status)="data">
						<span v-if="data.item.status==1" class="badge badge-success"> Verified</span>
						<span v-else class="badge badge-danger"> Not verified</span>
			</template>
      
      </b-table>
    </b-card>
  </b-col>
 </b-row>
    

    <b-row>
      <div class="col-lg-4 col-md-12">
				<div style="height: 511px;"  class="card d-flex justify-center">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2"> Event Type</h5>
						<hr>
					</div>
					<div class="card-body h-100" >
            <div>
              <vue-apex-charts type="pie"  :options="chartOptions1" :series="series1" width="375" align="left"></vue-apex-charts> 
						
            </div>
						
					</div>
				</div>
			</div>
      <div class="col-lg-4 mb-4 mb-lg-0">
				<div class="card h-100">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2"> Upcoming Payment</h5>
						
					</div>
					<div class="table-responsive">
						<table class="table table-borderless border-top">
							<thead class="border-bottom venue-color" >
								<tr>
									<th>Invoice Number</th>
									<th>Month</th>
									<th>Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(ub,b) in booking_count.vb_invoice" :key="b" :class="ub.status==1 ? 'bg-paid':''">
									<td>
										<div class="d-flex justify-content-start align-items-center">
											<div class="me-3">
												
											</div>
											<div class="d-flex flex-column">
												<p class="mb-0 fw-medium">{{ ub.invoice_number }}</p>
											</div>
										</div>
									</td>
									
									<td>{{ date_format(ub.first_date) }}</td>
									<td class="text-right">
										<p class="mb-0 fw-medium">
										<span v-html="currency_check(numberWithCommas(Math.round(ub.vb_amount)))"></span></p>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
      <div class="col-lg-4 col-md-12">
				<div class="card ">
					<div class="card-header d-flex justify-content-between">
						<h5 class="card-title m-0 me-2 pt-1 mb-2 d-flex align-items-center"><i class="ti ti-list-details ms-n1 me-2"></i> Activity Logs</h5>
						
					</div>
					<div class="card-body pb-0" style="height: 430px;">
						<ul class="timeline ms-1 mb-0">
							
							<li class="timeline-item timeline-item-transparent ps-4" v-for="(logs,li) in Activity_logs" :key="li">
								<span class="timeline-point "></span>
								<div class="timeline-event">
									<div class="timeline-header">
										<h6 class="mb-0">{{ JSON.parse(logs.description).message}}</h6>
										
									</div>
									<small class="text-muted">{{logs.log_time}}</small>
									<!--  <p class="mb-2">{{ JSON.parse(logs.description).message}}</p> -->
									
								</div>
							</li>
							
						</ul>
					</div>
				</div>
			</div>
    </b-row>
   
  </div>
</template>
<script>
 // import Lottie from '../../../lottie.vue';
  //import * as animationData from '../../assets/pinjump.json'; 

  //import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs



  import global from '../../mixins/global'; 
import VueApexCharts from "vue-apexcharts";
 const themeColors = ["#2962ff", "#35b1e8", "#fbcd00", "#f64e60", "#0bb7af"];
import axios from "axios";
export default {
  name: "Dashboard",
  mixins:[global],
  data: () => ({
    title: "Dashboard",
    parent_selected:'',
    Activity_logs:[],
    get_last_vendors:[],
			child_selected:'',
    booking_count:'',
    load_bookings:true,
    load_vendors:true,
    allcounts:0,
    curncy:'',
			user_name:'',
			select_mode:'',
			timerCount: 0,
			page: 1,
			show: false,
			countDownToTime : new Date("Sep 26, 2029 00:00:00").getTime(),
			count: 0,
			totalData: "",
			totalRows: "",
			loadImage:localStorage.getItem('loadImage')?localStorage.getItem('loadImage') :'' ,
			logo:"require('@/assets/images/venue_booking/logo.svg')",
    total_dashboard_counts: [],
    //defaultOptions: {animationData: animationData},
        animationSpeed: 1,
        anim: '',
    options: {
                    minimizable: false,
                    playerSize: "standard",
                    backgroundColor: '#fff',
                    backgroundStyle: 'color',
                    theme: {
                        controlsView: "standard",
                        active: "light",
                        light: {
                            color: '#3D4852',
                            backgroundColor: '#fff',
                            opacity: '0.7',
                        },
                        dark: {
                            color: '#fff',
                            backgroundColor: '#202020',
                            opacity: '0.7',
                        }
                    }
                },
   // defaultOptions: {animationData: animationData},
      //  animationSpeed: 1,
    bar_chart: {
      series: [
        {
          data: [44, 55, 41, 64, 22, 43, 21, 50, 67, 89, 12, 34],
        },
        {
          data: [53, 32, 33, 52, 13, 44, 32, 67, 78, 78, 89, 90],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 300,
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sepember",
            "October",
            "November",
            "December",
          ],
        },
      },
    },
    lineChartSimple: {
      series: [
        {
          name: "Desktops",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 59, 78, 90],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          zoom: {
            enabled: false,
          },
        },
        colors: themeColors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            opacity: 0.5,
          },
          borderColor: "rgba(0,0,0,0.1)",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "August",
            "Sepember",
            "October",
            "November",
            "December",
          ],
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        tooltip: {
          theme: "dark",
        },
      },
    },
    searchChart: {
      series: [14, 15],
      chartOptions: {
        labels: ["Search Traffic", "Free"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: "85px",
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: ["#0dcaf0", "#0d6efd"],
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    fields: [
      {
        key: "refno",
        label: "Booking Ref. No.",
        sortable: true,
      },
      {
        key: "customer_name",
        label: "Customer Name",
        sortable: true,
      },
      {
        key: "venue_name",
        label: "Venue Name",
        sortable: true,
      },
      {
        key: "date",
        label: "Booking Date",
        sortable: true,
      },
      {
        key: "price",
        label: "Price",
        sortable: true,
      },
    ],
    fields1: [
      {
        key: "id",
        label: "Vendor ID",
        sortable: true,
      },
      {
        key: "vendor_name",
        label: "Vendor Name",
        sortable: true,
      },
      {
        key: "mobile_no",
        label: "Mobile No",
        sortable: true,
      },
      
      {
        key: "status",
        label: "Status",
        sortable: true,
      },
    ],
    series1: [],

			chartOptions1: {
			chart: {
              width: '100%',
              type: 'pie',
            },
            labels: [],
			colors:['#FFC300', '#FF0000', '#B2FF00', '#088938', '#0AF2F6', '#0A57F6', '#0716A7', '#F10606', '#F10667', '#F106A4', '#E706F1', '#BC06F1', '#9F06F1','#412FE3', '#5006F1'],
            plotOptions: {
              pie: {
                dataLabels: {
                  offset: -5,
				
                }
              }
            },
            title: {
             // text: "Monochrome Pie"
            },
            dataLabels: {
				
              /* formatter(val, opts) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, val.toFixed(1) + '%']
              } */
            },
            legend: {
              show: true,
			position: "bottom",
			horizontalAlign: "left",
        offsetX: -5,
        offsetY: 5,
        fontWeight: "bold",
            }
			},
     walletChart: {
      series: [
        {
          name: "Balance",
          data: [5,10,15,20,30,45],
        },
      ],
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        colors: ["#4fc3f7"],
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        markers: {
          size: 3,
          strokeColor: "transparent",
        },
        fill: {
          type: "solid",
          opacity: 0,
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: { left: 0, right: 0 },
          },
        ],
        tooltip: {
          x: { show: false },
           enabled: false,
          theme: "dark",
        },
      },
    },
      referralChart: {
      series: [
        {
          name: "Net Profit",
          data: [6, 10, 9, 11, 9, 10, 11],
        },
      ],

      chartOptions: {
        colors: ["#2962FF"],
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "flat",
            columnWidth: "30%",
          },
        },
        chart: {
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          toolbar: {
            show: false,
          },
          sparkline: { enabled: true },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          stroke: {
            show: false,
          },
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: false,
          theme: "dark",
        },
      },
    },
    items: [],
    get_upcoming_booking: [],
  }),
  methods: {
    getBookingCount() {
				axios.post("/admin/get_vendor_dashboard_count",{
					parent_venue_id:this.parent_selected.value,
					child_venue_id:this.child_selected.value,
					mode:this.select_mode,
					}).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.booking_count = resp.data.total_counts;
							this.allcounts = parseInt(this.booking_count.total_confimed_booking)+ parseInt(this.booking_count.total_blocked_booking)+ parseInt(this.booking_count.total_draft_booking) + parseInt(this.booking_count.total_quot_booking)
							
						}
					}
				});
			},
			getUserDetails() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						this.$store.commit("SET_KYC_NOTIFICATION", resp.data.user_details);
            this.curncy= resp.data.user_details.currencys;
						this.user_name= resp.data.user_details.name;
						this.role_id= resp.data.user_details.role_id;
           // this.role_id = resp.data.users.role_id;
					
						this.logo= process.env.VUE_APP_APIURL+''+resp.data.user_details.logo;
						this.loadImage= process.env.VUE_APP_APIURL+''+resp.data.user_details.logo;
						localStorage.setItem('loadImage',this.logo);
						this.show= false;
						
						
					}
				});

			},
			getBookingList() {
				axios.post("/admin/get_vendor_bookings").then((resp) => {
					if (resp.data.status_code == 200) {
            this.load_bookings=false;
            this.load_vendors=false;
						this.get_recent_booking = [];
						this.get_upcoming_booking = [];
						this.get_last_vendors = [];
						this.pie_series = [];
						if (resp.data.status) {

              // for upcoming
							
							for (
							var a = 0;
							a < resp.data.bookings.get_upcoming_booking.bookings.length;
							a++
							) {
								
								this.get_upcoming_booking.push({
									addon:
									resp.data.bookings.get_upcoming_booking.bookings[a].addOns? resp.data.bookings.get_upcoming_booking.bookings[a].addOns : '', 
									date:
									resp.data.bookings.get_upcoming_booking.bookings[a].from_date ,
									refno: resp.data.bookings.get_upcoming_booking.bookings[a].booking_auto_id,
									customer_name: resp.data.bookings.get_upcoming_booking.bookings[a].billing_first_name,
								venue_name:resp.data.bookings.get_upcoming_booking.bookings[a].child_venue.child_venue_name.substring(0,25),
									price:resp.data.bookings.get_upcoming_booking.bookings[a].total_booking_price,  
									ament:resp.data.bookings.get_upcoming_booking.bookings[a].amenties,
								});
							}
              
              for (
							var k = 0;
							k < resp.data.get_last_vendor.length;
							k++
							) {
								if(resp.data.get_last_vendor[k].vendor_auto_id!='')
              {
								this.get_last_vendors.push({
								
									id:resp.data.get_last_vendor[k].vendor_auto_id,
									vendor_name:resp.data.get_last_vendor[k].company_name,
									mobile_no:resp.data.get_last_vendor[k].phone,
									status:resp.data.get_last_vendor[k].pan_no!=null || resp.data.get_last_vendor[k].adhar_no!=null ? 1 : 0 ,
								});
							}
            }
							// for incoming
							
							this.Activity_logs=resp.data.bookings.Activity_logs;
							this.last_transaction=resp.data.bookings.last_transaction;
							for (let k = 0; k < resp.data.bookings.pie.pie_array.length; k++) 
							{
								//this.series_[0].data.push(resp.data.bookings.pie.pie_array[k]);
								this.series1.push(resp.data.bookings.pie.pie_array[k]);
								this.chartOptions1.labels.push(resp.data.bookings.pie.name_arry[k]);
							}
							
							
						/*	for (let l = 0; l < resp.data.bookings.getMonthlyData.month.length; l++) 
							{
								this.$refs.booking_charts.refresh(
								( this.chartOptions_.xaxis.categories.push(resp.data.bookings.getMonthlyData.month[l])),
								( this.series_[0].data.push(resp.data.bookings.getMonthlyData.amount[l]))
								);
								
							}*/
							
							
							this.Block_details=resp.data.bookings.Block_details;
							
							
							
						
						}
					}
				});
			},
      numberWithCommas(x) {
           /*  if(x)
            {
                return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
            }
            else
            {
                return 0;
            } */
            var num = x ? x : 0;
				return num.toLocaleString('en-IN')
        },
      lets_start_time:function(todatetime)
        {
            const blockedTime = new Date().getTime();
            const blockTime = new Date(todatetime).getTime();
            const timeDifference = blockTime - blockedTime;
            const millisecondsInOneSecond = 1000;
            const millisecondsInOneMinute = millisecondsInOneSecond * 60;
            const millisecondsInOneHour = millisecondsInOneMinute * 60;
            const millisecondsInOneDay = millisecondsInOneHour * 24;
            const differenceInDays = timeDifference / millisecondsInOneDay;
            const remainderDifferenceInHours = (timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
            const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
            const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
            
            const remainingDays = Math.floor(differenceInDays);
            const remainingHours = Math.floor(remainderDifferenceInHours);
            const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
            const remainingSeconds =Math.floor(remainderDifferenceInSeconds);
            
            var timerCounter=0;
            if(remainingDays!=0)
            {
                var thr=remainingDays*24;
                var timg = parseInt(remainingHours)+parseInt(thr);
                timerCounter=timg +":"+ remainingMinutes +":"+ remainingSeconds;
            }
            else
            {
                timerCounter=remainingHours +":"+ remainingMinutes +":"+ remainingSeconds;
            }
         
            if(timerCounter<="0:0:0")
            {
                this.blocktimeUpdate();
            }
            
            return timerCounter;
        },
        blocktimeUpdate() {
          axios.get("/admin/autocallCalender").then((resp) => {
         resp
       });
   },
    handleAnimation: function (anim) {
        this.anim = anim;
      },
 
      stop: function () {
        this.anim.stop();
      },
 
      play: function () {
        this.anim.play();
      },
 
      pause: function () {
        this.anim.pause();
      },
 
      onSpeedChange: function () {
        this.anim.setSpeed(this.animationSpeed);
      }, 
   currency_check(amt) {
				return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
      load_lottie()
      {
        return require('../../assets/Animation-1.1-Blue.json').default;
      }
  },
  mounted() {
    this.getUserDetails();
    this.getBookingCount();
    this.getBookingList();
    //this.handleAnimation();
   
  },
  
  components: { VueApexCharts  },
};
</script>
<style scoped>
#icon_first {
  font-size: 35px;
}
.card-header {
  font-size: 20px;
}
</style>